const initBookingDownload = () => {
  
  const runBookingDownload = async (event) => {
    const element = $(event.currentTarget);
    let { resource, filters, kind } = element.data();
    let data = {...{ resource, kind }, ...filters}; // Merge filters into data
    notify_success("Processing your request, downloads will begin momentarily ...")
    let result = await whisper('/bookings/download', data, 'Error')
    if (result.download) {
      let title = kind.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
      notify_success(title + ' downloading<br><br>It will be emailed to <strong class="has-text-white">' + result.email + "</strong>.");
    } else {
      notify_danger(result.error)
    }
  };

  $(".svp-body").on("click", ".run-booking-download", runBookingDownload);
};

export { initBookingDownload };
