import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import "select2";

export default class extends Controller {
  static values = { options: Object, additionalClass: String, additionalClassTarget: String }

  connect() {
    $(this.element).select2(this.optionsValue);

    // Add additional classes to specific select2 elements
    const additionalClass = this.additionalClassValue;
    const additionalClassTarget = this.additionalClassTargetValue;

    if (additionalClass && additionalClassTarget) {
      $(`.${additionalClassTarget}`, $(this.element).parent()).addClass(additionalClass);
    }
  }
}
